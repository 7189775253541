import React from 'react';
import crn from './assets/crn_home.jpg';
import './App.css';

function App() {
  return (
    <div className="bg-beige h-screen w-screen flex flex-col gap-8 items-center justify-center px-4 text-center">

      <img src={crn} alt="" className='max-h-[50vh] sm:h-[420px] h-auto w-auto rounded-md shadow-md' />
      <h2>website under construction</h2> 
    </div>
  );
}

export default App;
